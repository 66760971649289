import { switchMap } from 'rxjs'
import { Component, Input, OnInit } from '@angular/core'
import { UntypedFormBuilder, ValidationErrors, Validators } from '@angular/forms'
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker'
import { ActivatedRoute } from '@angular/router'
import { AuthService } from '../../auth/services/auth.service'
import { ScriveEidService } from '../../services/scrive-eid/scrive-eid.service'
import { ProviderType, ShoutlyEidAddTransactionRequest } from '../../auth/models/auth.model'

@Component({
  selector: 'shared-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss']
})
export class EmailComponent implements OnInit {
  @Input() sign_type: 'register' | 'login' = 'login'
  @Input() org_type: 'gigger' | 'employer' | 'agency' = 'gigger'
  @Input() org_form: 'Business' | 'Private' = 'Business'

  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private scriveEidService: ScriveEidService,
    private route: ActivatedRoute
  ) { }

  get passwordRegex() {
    return /^(?=.*\p{Ll})(?=.*\p{Lu})(?=.*\d)(?=.*[@$!%*?&.?><,;:'"[{\]}()])(?!.*#)[\p{L}\d@$!%*?&.?><,;:'"[{\]}()]{7,}$/u;
  }

  public transaction_id = null

  public showUserNotFoundError: string = null

  public isLoading = false

  public passwordHidden = true

  public specialCharactersAllowed = "@ $ ! % * ? & . > < , ; : ' \" [ { } ] ( )."

  passwordValidations = {
    minLength: false,
    letter: false,
    specialChar: false
  };

  ngOnInit(): void {
    this.getTransactionId()
    console.log('EmailComponent ngOnInit', this.sign_type)

    if (this.sign_type === 'register') {
      this.emailMethodForm.get('password').setValidators([
        Validators.required,
        Validators.minLength(8),
        Validators.pattern(this.passwordRegex)
      ]);

      this.emailMethodForm.get('password').valueChanges.subscribe(() => {
        this.validatePassword();
      });
    } 
    else {
      this.emailMethodForm.get('password').clearValidators();
      this.emailMethodForm.get('password').updateValueAndValidity();
    }
  }

  emailMethodForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required]]
  })

  validatePassword(): void {
    if (this.sign_type !== 'register') {
      return;
    }
    const password = this.emailMethodForm.get('password').value;
    const allowedSpecialCharacters = /[@$!%*?&.><,;:'"\[\]{}()]/;
    const disallowedSpecialCharacters = /[^a-zA-Z0-9@$!%*?&.><,;:'"\[\]{}()]/;
  
    this.passwordValidations.minLength = password.length >= 8;
    this.passwordValidations.letter = /[a-zA-Z]/.test(password);
  
    if (disallowedSpecialCharacters.test(password)) {
      this.emailMethodForm.get('password').setErrors({ disallowedSpecialChar: true });
      this.passwordValidations.specialChar = false;
    } else {
      this.emailMethodForm.get('password').setErrors(null);
      this.passwordValidations.specialChar = allowedSpecialCharacters.test(password);
    }
  }

  getTransactionId() {
    const invite_token = this.route.snapshot.queryParams.token ?? null

    const request: ShoutlyEidAddTransactionRequest = {
      type: this.sign_type,
      provider: ProviderType.Email,
      org_type: this.org_type,
      org_form: this.org_form,
      invite_token
    }

    return this.scriveEidService.getToken(request)
      .subscribe({
        next: data => { this.transaction_id = data.id },
        error: () => {
          this.isLoading = false
          this.transaction_id = 'error'
          this.handleError(_('Network error. Please try again later.'))
        }
      })
  }

  handleError(error: string) {
    this.showUserNotFoundError = error
  }

  showErrorsOnForm(validationErrors: ValidationErrors) {
    const form = this.emailMethodForm

    form.markAllAsTouched()

    Object.keys(validationErrors).forEach(prop => {
      const formControl = form.get(prop)
      if (formControl) {
        formControl.setErrors({
          serverError: validationErrors[prop]
        })
      }
    })
  }

  public onSubmit() {
    this.isLoading = true

    const body = {
      email: this.emailMethodForm.value?.email,
      password: this.emailMethodForm.value?.password
    }

    return this.authService.eidSubmitEmailAuth(this.transaction_id, body)
      .pipe(
        switchMap(
          data => {
            const sign_type = this.sign_type

            return sign_type === 'register'
              ? this.authService.registerUserObservable(this.transaction_id)
              : this.authService.loginUserObservable(this.transaction_id)
          }
        )
      )
      .subscribe({
        error: (e) => {
          this.isLoading = false
          const error = e.error?.errors || e.error?.err_mess || 'Error during authentication'
          this.handleError(error)
          if (e.error?.errors) {
            this.showErrorsOnForm(e.error.errors)
          }
        }
      })
  }
}
