<div class="main-title">
    <h3 translate>{{ (sign_type | translate) | titlecase }} {{'with EMAIL' | translate}}</h3>
</div>

<div>
    <p class="signup">
        {{ 'Please enter your email' | translate | ucfirst }} 
    </p>
</div>
<br>

<div *ngIf="showUserNotFoundError as error">
    <shared-infobox
    status="negative">
    {{error}}
    </shared-infobox>
    <br>
</div>

<form [formGroup]="emailMethodForm" style="text-align: left;" (ngSubmit)="onSubmit()">
    <mat-form-field>
        <mat-label>{{'email' | translate | titlecase}}</mat-label>
        <mat-icon fontSet="material-icons-outlined" matPrefix>email</mat-icon>
        <input type="email" matInput formControlName="email" placeholder="your@email.com">
        <mat-error>{{emailMethodForm.get('email').errors?.serverError}}</mat-error>
    </mat-form-field>
    <mat-form-field>
        <mat-label>{{ 'password' | translate | titlecase }}</mat-label>
        <input [type]="passwordHidden ? 'password' : 'text'" matInput formControlName="password" (input)="validatePassword()">
        <mat-icon fontSet="material-icons-outlined" matPrefix>lock</mat-icon>
      
        <button 
          *ngIf="emailMethodForm.get('password').getRawValue()?.length"
          (click)="passwordHidden = !passwordHidden"
          type="button"
          mat-icon-button
          matSuffix
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="passwordHidden">
          <mat-icon>{{passwordHidden ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
      
        <button
          *ngIf="sign_type === 'register'"
          type="button"
          [matTooltip]="('It should contain at least one lowercase letter, at least one uppercase letter, at least one digit and at least one special character' | translate) + ': ' + specialCharactersAllowed"
          mat-icon-button
          matSuffix
          [attr.aria-label]="'Displays a tooltip when focused or hovered over'">
          <mat-icon>help_outline</mat-icon>
        </button>
        <mat-error *ngIf="emailMethodForm.get('password').hasError('disallowedSpecialChar')">
          Password contains disallowed special characters.
        </mat-error>
        <mat-error>{{emailMethodForm.get('password').errors?.serverError}}</mat-error>
      </mat-form-field>
      
      <div class="password-requirements" *ngIf="sign_type === 'register'" style="display: flex; flex-direction: column;">
        <mat-checkbox [checked]="passwordValidations.minLength">{{ '8 characters' | translate | titlecase }}</mat-checkbox>
        <mat-checkbox [checked]="passwordValidations.letter">{{ '1 lowercase/uppercase character' | translate | titlecase }}</mat-checkbox>
        <mat-checkbox [checked]="passwordValidations.specialChar" matTooltip="Allowed special characters: @ $ ! % * ? & . > < , ; : ' [ { } ] ( )." matTooltipPosition="right">{{ '1 allowed special character' | translate | titlecase }}</mat-checkbox>
      </div>
    <br>
    <!-- <password-strength-meter 
        *ngIf="sign_type === 'register'"
        [password]="emailMethodForm.get('password').value"
    ></password-strength-meter> -->
    <button 
        [disabled]="isLoading || emailMethodForm.invalid || (sign_type === 'register' && (!passwordValidations.minLength || !passwordValidations.letter || !passwordValidations.specialChar))"
        mat-flat-button 
        type="submit" 
        color="primary" 
        style="width:100%;"
    >
    {{ sign_type === 'login' ? ('log in' | translate | titlecase) : (transaction_id && !isLoading ? ('sign up' | translate | titlecase) : 'Requesting...') }}
    </button>

    <div class="forgot-password-wrapper" style="padding: 10px;" *ngIf="sign_type === 'login'">
        <a [routerLink]="['/auth/password/reset']" translate><strong> Forgot your password?</strong></a>
    </div>
    <div class="auth-notice">
        <div class="secondary-text">
            This site is protected by reCAPTCHA and the Google
            <a href="https://policies.google.com/privacy">Privacy Policy</a> and
            <a href="https://policies.google.com/terms">Terms of Service</a> apply.
        </div>
    </div>
</form>

