import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core'
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms'
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker'
import { EMPTY, Observable, Subject, catchError, switchMap } from 'rxjs'
import { HttpErrorResponse } from '@angular/common/http'
import { ActivatedRoute, Router } from '@angular/router'
import { AuthService } from '../../auth/services/auth.service'
import { OrgForm, OrgType, ProviderType, ShoutlyEidAddTransactionRequest, ShoutlyEidTransactionResponse, SignType } from '../../auth/models/auth.model'
import { ScriveEidService } from '../../services/scrive-eid/scrive-eid.service'
import { NgOtpInputComponent } from 'ng-otp-input'
import { PhoneNumberInput } from '../../models/phone.model'
import { ApiErrorResponseV1 } from '../../models/_errors.model'

_('Identification for this personal number is already started. Complete previous transaction or wait for a minute in order to start new one.')

@Component({
  selector: 'shared-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss']
})
export class OtpComponent implements OnChanges {
  @ViewChild(NgOtpInputComponent, { static: false}) ngOtpInput: NgOtpInputComponent
  @Input() org_type: OrgType = 'gigger'
  @Input() sign_type: SignType = 'login'
  @Input() org_form: OrgForm = 'Business'

  constructor(
    private scriveEidService: ScriveEidService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  provider: ProviderType = ProviderType.SmsOtp
  transaction_id: string
  transaction$: Subject<ShoutlyEidTransactionResponse> = new Subject<ShoutlyEidTransactionResponse>()
  countries
  phoneNumber: PhoneNumberInput
  validationErrors: ValidationErrors = null
  enableRetry = false
  isLoading = false
  showError: string = null
  buttonText = 'Verify'

  public readonly otpInputConfig = {
    length:6,
    allowNumbersOnly: true,
    inputStyles: {
      width: 'calc(100% / 6)',
      height: '50px'
    },
    containerStyles: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    placeholder: ''
  }

  public otpCodeForm = new UntypedFormGroup({
    code: new UntypedFormControl(null, [Validators.required, Validators.minLength(6)])
  })

  ngOnChanges(changes: SimpleChanges): void {
    if(this.sign_type === 'register' && this.org_type == null) {
     console.error('user type missing')
    } else {
      this.getTransactionId()
    }
  }

  /* SERVICE */
  getTransactionId() {
    const invite_token = this.route.snapshot.queryParams.token ?? null

    const request: ShoutlyEidAddTransactionRequest = {
      type: this.sign_type,
      provider: this.provider,
      org_type: this.org_type,
      org_form: this.org_form,
      invite_token
    }

    return this.scriveEidService.getToken(request)
      .subscribe({
        next: data => { this.transaction_id = data.id },
        error: () => {
          this.isLoading = false
          this.transaction_id = 'error'
          this.handleError(_('Network error. Please try again later.'))
        }
      })
  }

  private handleError(error: string) {
    this.showError = error
  }

  onSubmitPhone(mobile_number) {
    this.isLoading = true

    this.scriveEidService.eidStart(mobile_number)
      .subscribe({
        next: data => {
          this.transaction$.next(data)
          this.isLoading = false
        },
        error: (err: HttpErrorResponse) => {
          this.storePhoneValidationErrors(err.error as ValidationErrors)
          this.isLoading = false
        }
      })
  }

  storePhoneValidationErrors(validationErrors: ValidationErrors) {
    this.validationErrors = validationErrors
  }
  onSubmitKey() {
    const transactionId = this.transaction_id
    const code = this.otpCodeForm.get('code').value

    this.isLoading = true

    this.scriveEidService.eidSubmitOTPCode(code, transactionId)
      .pipe(
        catchError((err: ApiErrorResponseV1) => {
          if (err.error.err_type === 'InvalidCode' && err.error.err_mess === '0') {
            this.reset()
          } else {
            this.onOtpErrorResponse(`${err.error.err_mess} attempts left`, this.otpCodeForm.get('code'))
          }
          this.isLoading = false
          return EMPTY // Prevents the subsequent operations if there's an error
        }),
        switchMap(() => this.handleSuccess()), // Switch to the next observable on success
        catchError((err: ApiErrorResponseV1) => {
          // Handle errors from the handleSuccess observable here
          if (err.error?.err_type === 'UserNotFound') {
            this.router.navigate(['auth', 'signup'], { queryParams: { userNotFound: true } })
          }
          this.isLoading = false
          return EMPTY
        })
      )
      .subscribe({
        complete: () => (this.isLoading = false) // Only runs if the entire observable chain completes successfully
      })
  }

  handleSuccess(): Observable<any> {
    const signType = this.sign_type
    return signType === 'register'
      ? this.authService.registerUserObservable(this.transaction_id)
      : this.authService.loginUserObservable(this.transaction_id)
  }

  onOtpErrorResponse(serverError: string, formControl: AbstractControl) {
    // set error in field
    formControl.setErrors({ serverError })
    // clear otp
    this.ngOtpInput.otpForm.reset()
    this.ngOtpInput.otpForm.enable()
  }

  patchPhoneControl(phone: PhoneNumberInput) {
    this.phoneNumber = phone
  }

  private reset() {
    this.transaction$ = new Subject<ShoutlyEidTransactionResponse>()
    this.transaction_id = null
    this.validationErrors = null
    this.enableRetry = false
    this.isLoading = false
    this.showError = null
    this.otpCodeForm.reset()
    this.getTransactionId()
  }

  private autoSubmitOtpCode(){
    setTimeout(() => {
      this.ngOtpInput.otpForm.disable()
      this.onSubmitKey()
    }, 200)
  }

  public onOtpChange(code: string) {
    this.otpCodeForm.patchValue({code})
    if(code.length === 6){
      this.autoSubmitOtpCode()
      this.updateButtonText(code)
    }
  }

  updateButtonText(value: string): void {
    if (/^\d{6}$/.test(value)) {
      this.buttonText = 'Sign in';
    } else {
      this.buttonText = 'Verify';
    }
  }

  resendCode() {
    this.getTransactionId();
    setTimeout(() => {
      this.onSubmitPhone(this.phoneNumber.value);
    }, 2000);
  }
}
