<div class="main-title">
    <h3 translate>{{ (sign_type | translate) | titlecase }} {{'with SMS' | translate}}</h3>
</div>

<div *ngIf="showError as error">
    <shared-infobox
    status="negative">
    {{error}}
    </shared-infobox>
    <br>
</div>

<ng-container *ngIf="(transaction$| async)?.status !== 'started'">
    <div class="secondary-text">
        <p>{{'Please enter your phone number' | translate }}</p>
        <br>
    </div>
    <app-mobile-phone-input (onPhoneChanges)="patchPhoneControl($event)" (onEnter)="onSubmitPhone(phoneNumber.value)"
        [validationErrors]="validationErrors"></app-mobile-phone-input>

    <button [disabled]="!(transaction_id) || !phoneNumber?.isValid || isLoading" mat-flat-button type="submit"
        color="primary" style="width:100%;" translate (click)="onSubmitPhone(phoneNumber.value)">
        {{ !!transaction_id ? 'Text me a code' : 'Requesting...' }}
    </button>
</ng-container>

<form *ngIf="(transaction$| async)?.status === 'started'" [formGroup]="otpCodeForm" style="text-align: left;"
    (ngSubmit)="onSubmitKey()">
    <shared-infobox
    *ngIf="otpCodeForm.get('code').errors?.serverError as error"
    status="negative">
    {{error}}
    </shared-infobox>

    <div class="secondary-text">
        <p>{{'Please insert the 6-digit code below to complete your registration' | translate }}</p>
        <!-- <p>{{'SMS sent to:' | translate}} {{' ' + phoneNumber.value}}</p> -->
    </div>

    <div class="otp-input-wrapper">
        <ng-otp-input (onInputChange)="onOtpChange($event)" [config]="otpInputConfig"></ng-otp-input>
    </div>

    <ng-container>
        <button [disabled]="otpCodeForm.invalid || isLoading" mat-flat-button type="submit" color="primary"
            style="width:100%;">
            {{ buttonText | translate | titlecase }}
        </button>
        <br>
        <div>
            <br>
            <p class="signup" style="padding-left: 10px;">
                {{ 'Didn`t receive the code?' | translate | ucfirst }} 
                <a class="clickable" (click)="resendCode()">
                    {{ 'Resend code' | translate | ucfirst }}
                </a>
            </p>
        </div>
    </ng-container>
    <ng-container *ngIf="otpCodeForm.get('code').errors?.serverError === '0'">
        <button mat-flat-button type="submit" color="primary" style="width:100%;">
            {{ 'try again' | translate | titlecase }}
        </button>
    </ng-container>
</form>

<div class="auth-notice">
    <div class="secondary-text">
        This site is protected by reCAPTCHA and the Google
        <a href="https://policies.google.com/privacy">Privacy Policy</a> and
        <a href="https://policies.google.com/terms">Terms of Service</a> apply.
    </div>
</div>